<template>
  <div class="register">
    <div class="w ww zhuce">
      <div class="pc_fl zhuce_lf">
        <h2>{{ this.$t("createAccount") }}</h2>
        <h3>{{ this.$t("newCome") }}</h3>
        <p>
          <span>{{ this.$t("reBenefits1") }}</span
          >{{ this.$t("reBenefits2") }}<br />{{ this.$t("reBenefits3")
          }}<br />{{ this.$t("reBenefits4") }}
        </p>
        <div class="zhuce_lf_1">
          <img src="@/assets/img/logo.png" width="100%" />
        </div>
      </div>
      <div class="pc_fr zhuce_rt">
        <table width="100%">
          <tr>
            <td></td>
            <td>
              <h3>{{ this.$t("accountReg") }}</h3>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <span
                class="register-phone"
                :class="{ actived: isShow }"
                @click="phone"
                >{{ this.$t("method1") }}</span
              >
              <span
                class="register-email"
                :class="{ actived: !isShow }"
                @click="email"
                >{{ this.$t("method2") }}</span
              >
            </td>
          </tr>
          <tr>
            <th>{{ this.$t("name") }}：</th>
            <td>
              <input
                type="text"
                :placeholder="this.$t('iptName')"
                v-model="registerFrom.realName"
              />
            </td>
          </tr>
          <tr>
            <th>
              <i class="compName_required">*</i>{{ this.$t("compNames") }}：
            </th>
            <td>
              <input
                type="text"
                :placeholder="this.$t('iptComName')"
                v-model="registerFrom.compName"
              />
            </td>
          </tr>
          <tr v-if="isShow">
            <th><i class="compName_required">*</i>{{ this.$t("phone") }}：</th>
            <td>
              <input
                name=""
                v-model="registerFrom.userMobile"
                type="text"
                :placeholder="this.$t('phoneReg')"
              />
            </td>
          </tr>
          <tr v-else>
            <th><i class="compName_required">*</i>{{ this.$t("email") }}</th>
            <td>
              <input
                name=""
                v-model="registerFrom.userEmail"
                type="text"
                :placeholder="this.$t('emailReg')"
              />
            </td>
          </tr>
          <tr>
            <th>
              <i class="compName_required">*</i>{{ this.$t("password") }}：
            </th>
            <td>
              <input
                name=""
                v-model="registerFrom.userPwd"
                type="password"
                :placeholder="this.$t('passwordReg')"
              />
            </td>
          </tr>
          <tr>
            <th>
              <i class="compName_required">*</i>{{ this.$t("surePassword") }}：
            </th>
            <td>
              <input
                name=""
                v-model="registerFrom.userPwd1"
                type="password"
                :placeholder="this.$t('Confirm')"
              />
            </td>
          </tr>

          <tr>
            <th>
              <i class="compName_required">*</i>{{ this.$t("testCode") }}：
            </th>
            <td>
              <div class="zhuce_rt_1">
                <input
                  name=""
                  v-model="registerFrom.code"
                  type="text"
                  :placeholder="this.$t('inputCode')"
                />
                <input
                  name=""
                  type="button"
                  v-if="show"
                  :value="this.$t('getCode')"
                  @click.prevent="getVerifyCode"
                />
                <input
                  name=""
                  type="button"
                  v-else
                  :value="count + '秒后重新获取'"
                  disable
                  class="disable-btn"
                />
                <div class="clear"></div>
              </div>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <label class="zhuce_rt_2">
                <input
                  class="demo--radio"
                  type="checkbox"
                  name="demo-checkbox2"
                  @click="state = !state"
                  v-model="state"
                />
                <span class="demo--checkbox demo--radioInput"></span
                >{{ this.$t("agree") }}<a>{{ this.$t("agreement") }}</a>
              </label>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <button @click="register" @keyup="btnRegister">
                {{ this.$t("Reg") }}
              </button>
            </td>
          </tr>
          <tr>
            <th></th>
            <td>
              <p>
                {{ this.$t("haveAccount")
                }}<a @click="login">{{ this.$t("log") }}</a>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { userRegister, verifyCode, sendEmailCode } from "./model";
import { showLayer } from "@/utils/com";
import Bottom from "../../components/component/Bottom.vue";
export default {
  components: { Bottom },
  data() {
    return {
      // 获取验证码的返回信息
      codeInfo: {},
      // 注册的返回信息
      registerInfo: {},
      // 记录复选框按钮的状态
      state: false,
      //按钮禁用状态
      disable: true,
      show: true,
      timer: null,
      count: "",
      registerFrom: {
        userEmail: "",
        userMobile: "",
        userPwd: "",
        userPwd1: "",
        realName: "",
        compName: "",
        type: 0,
        code: "",
      },
      isShow: true,
    };
  },
  created() {
    this.btnRegister();
  },
  methods: {
    phone() {
      this.isShow = true;
    },
    email() {
      this.isShow = false;
    },
    login() {
      this.$router.push("/login");
    },
    //获取手机验证码
    getVerifyCode() {
      if (this.isShow) {
        verifyCode(this.registerFrom).then((res) => {
          this.codeInfo = res;
          if (this.codeInfo === "success") {
            showLayer("验证码获取成功!");
            this.show = false;
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        });
      } else {
        //获取邮箱验证
        sendEmailCode(this.registerFrom).then((res) => {
          if (res === "success") {
            showLayer("验证码获取成功!");
            this.show = false;
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        });
      }
    },
    //注册
    register() {
      const { userEmail, userMobile, userPwd, userPwd1, compName, code } =
        this.registerFrom;
      if (this.state === false) {
        showLayer("toast", "请勾选注册协议！");
        return;
      }
      //手机号注册
      if (this.isShow) {
        if (
          userMobile == "" ||
          userPwd == "" ||
          userPwd1 == "" ||
          compName == "" ||
          code == ""
        ) {
          showLayer("toast", "带红色星号的为必填项");
        } else {
          userRegister(this.registerFrom).then((res) => {
            this.registerInfo = res;
            if (this.registerInfo === "ok") {
              showLayer("toast", "注册成功！");
              this.$router.push("/login");
            }
          });
        }
      } else {
        //邮箱注册
        if (
          userEmail == "" ||
          userPwd == "" ||
          userPwd1 == "" ||
          compName == "" ||
          code == ""
        ) {
          showLayer("toast", "带红色星号的为必填项");
        } else {
          userRegister({
            userEmail: userEmail,
            userPwd: userPwd,
            userPwd1: userPwd1,
            code: code,
            realName:realName,
            compName:compName,
            type: 1,
          }).then((res) => {
            showLayer("toast", "注册成功！");
            if (res === "ok") {
              this.$router.push("/login");
            }
          });
        }
      }
    },
    btnRegister() {
      document.onkeydown = (e) => {
        if (e.keyCode === 13) {
          this.register();
        }
      };
    },
  },
};
</script>
<style scoped>
.disable-btn {
  font-size: 14px;
}
.actived {
  background: #32b16c;
  color: #fff !important;
}
.zhuce_rt input {
  font-size: 12px !important;
}
.compName_required {
  color: red;
}
</style>

